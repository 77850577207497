// Feedback.module.scss

.feedbackContainer {
  max-width: 90%;
  margin-bottom: 20px;
  padding: 15px;

  text-align: center;

  margin: 0 auto;
  .boxFeed {
    background-color: #212529;
    border: solid 1px white;
    border-radius: 15px;
    padding: 5px 20px;
    margin-bottom: 15px;
    h3 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    p {
        font-size: 0.9rem;
        margin-bottom: 10px;
      }
  }
}
.tg {
  cursor: pointer;
  color: blue;
}
.text{
  @media screen and (max-width: 490px) {
    width: 100%;
  }
}
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease; /* Анимация появления */
}

.modalContent {
  background-color: #333;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease; /* Анимация входа */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.link {
  bottom: 15px;
  width: 350px;
  padding: 10px;
  background-color: #dc3545;
  color: white;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #df0909;
  }
}
.inputName{
  input{
    width: 462px;
    @media screen and (max-width: 490px) {
      width: 100%;
    }
  }
  width: 100%;
  margin-bottom: 15px;
}