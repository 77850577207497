

.logo {
  // width: 100px;
  margin-right: 25px;
  height: 65px;

 
}
.tgbox{
  display: flex;
 color: rgba(255, 255, 255, 0.55); 
 align-items: center;
 cursor: pointer;
}

.telegramLink {
  width: 30px;
  margin-left: 30px;
  margin-right: 7px;
  @media screen and (max-width: 991px) {
   margin: 0;
  }
}