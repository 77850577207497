.admin-login {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      margin-bottom: 1.5rem;
      color: #333;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      div {
        margin-bottom: 1rem;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          color: #555;
        }
  
        input {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 3px;
          font-size: 1rem;
  
          &:focus {
            border-color: #007bff;
            outline: none;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
          }
        }
      }
  
      .error {
        color: red;
        margin-bottom: 1rem;
        text-align: center;
      }
  
      button {
        padding: 0.75rem;
        border: none;
        border-radius: 3px;
        background-color: #007bff;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }