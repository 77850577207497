.payment {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: 600px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3 {
    color: #003087; // Темно-синий
    font-weight: bold;
    margin-bottom: 15px;
  }

  .cardForPay {
  
    padding: 20px;
    margin-bottom: 20px;


    .btnCor {
      display: block;
      margin-bottom: 10px;
      padding: 10px 15px;
      background-color: #0072ce; // Ярко-синий
      color: white;
      text-align: center;
      border-radius: 5px;
      text-decoration: none;

      &:hover {
        background-color: #005bb5; // Темно-синий при наведении
      }
    }

    .boxInputPay {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .imgPay {
        width: 40px;
        margin-right: 10px;
      }

      .inputPay {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
      }

      
    }
  }
  .imgPaysbp {
    width: 60px;
    margin-right: 10px;
  }
  .boxSBP, .cardForPay {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .imgPay {
      width: 40px;
      margin-right: 10px;
    }
  }
  .btnCopy {
    background-color: #ffcc00; // Желтый
    color: #003087; // Темно-синий
    border: none;
    padding: 10px 15px;
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #e6b800; // Темно-желтый при наведении
    }
  }
  .inputPaySBP {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .btnCopySBP {
    background-color: #ffcc00; // Желтый
    color: #003087; // Темно-синий
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 8px;

    &:hover {
      background-color: #e6b800; // Темно-желтый при наведении
    }
  }
  .discription {
    margin-bottom: 20px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: black;
    ol {
      padding-left: 20px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  .price {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #003087; // Темно-синий
  }

  .btnsend {
    background-color: #0072ce; // Ярко-синий
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: #005bb5; // Темно-синий при наведении
    }
  }

  .bankImg {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    .imgBank {
      width: 180px;
      margin-right: 10px;
    }

    h4 {
      margin: 0;
      color: #003087; // Темно-синий
      cursor: pointer;

      @media screen and (max-width: 401px) {
        font-size: 13px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btnBack {
    background-color: #ffcc00; // Желтый
    color: #003087; // Темно-синий
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: #e6b800; // Темно-желтый при наведении
    }
  }
}
.telegramLink{
  width: 30px;
  @media screen and (max-width: 991px) {
   margin: 0;
  }
}