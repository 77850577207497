.detBox {
  margin-top: 80px;
  padding: 20px;
  display: flex;
  align-items: center;
  padding: 0 50px;
  @media screen and (max-width: 1015px) {
    flex-direction: column;
    padding: 15px;
  }
}

.imgDet {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin-bottom: 20px;
}
.prodInfo {
  width: 100%;
  margin-left: 20px;
  @media screen and (max-width: 1015px) {
    margin-top: 10px;
  }
}
.description {
  width: 60%;
  @media screen and (max-width: 1015px) {
    width: 100%;
  }
}
.tableDescr {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #333; /* Черный фон */
  color: #fff; /* Белый текст */
}

.tableDescLabel {
  font-weight: bold;
  padding: 8px;
  border-bottom: 1px solid #fff;
  text-align: left;
  background-color: #333; /* Темно-серый фон для ячеек с метками */
}

.tableDescValue {
  padding: 8px;
  border-bottom: 1px solid #fff;
  text-align: left;
}

.tableDescr tr:nth-child(even) {
  background-color: #333; /* Очень темный серый фон для четных строк */
}

.tableDescr tr:hover {
  background-color: #333; /* Темно-серый фон при наведении */
}
// .boxbtnBye{
//   padding: 5px;
// }
.btnBye {
  cursor: pointer;
  padding: 10px;
  background-color: #dc3545;
  text-align: center;
  border-radius: 15px;
  margin: 10px;
  color: white;
&:hover{
  background-color: #f3061d;
}
}
// .boxPlus{
//   display: flex;
//   margin-top: 10px;
// }
.plus {
  display: inline-block;
  background-color: #dc3545;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: small;
  margin-right: 5px;
  padding: 0 5px;
  @media screen and (max-width: 820px) {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  animation: slideDown 0.5s ease-out forwards;
  z-index: 5;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.modalContent {
  background: #212529;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
  text-align: center;
  margin-top: 5%;
  color: white;
  @media screen and (max-width: 480px) {
    margin-top: 2%;
    width: 90%;
    padding: 6px 20px;
  }
}

.modalActions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modalButton {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #dc3545;
  color: white;
  border-radius: 5px;
}

.modalButton:hover {
  background-color: #dc3545;
}
.iconImg{
  margin-right: 5px;
  cursor: pointer;
  @media screen and (max-width: 401px) {
   margin-top: 8px;
  }
}
.inputBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 7px;
  }
}
.formUp {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 1px;
  }

  p {
    margin: 0;
  }
  input,
  select {
    padding: 5px 10px;
    border-radius: 5px;
    height: 100%;
    background-color: #212529;
    color: white;
    border: solid 1px #495057;
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
  }
}
.totalPrice{
  display: none;
}
.dateInput{
  width: 100%;
}
.time{
  display: flex;
  select {
    width: 100%;
  }
}
.hour {
  margin-right: 5px;
}
.minute {
  margin-left: 5px;
}
.formGroup {
  margin-bottom: 5px;
  display: flex;
  p {
    margin: 0;
  }
}

.noScroll {
  overflow: hidden;
}
.margin{
  margin-top: 7px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Уменьшаем ширину на 10px */
  height: 22px; /* Уменьшаем высоту на 10px */
  margin-right: 10px;
  
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 15px; /* Подстраиваем радиус границы */
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px; /* Уменьшаем высоту круга на 8px */
  width: 15px; /* Уменьшаем ширину круга на 8px */
  left: 3px; /* Регулируем положение круга */
  bottom: 4px; /* Регулируем положение круга */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(18px); /* Смещаем круг вправо на 18px */
}
.iconMass{
  display: flex;
}