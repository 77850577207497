// BackCash.module.scss

.backcashForm {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  
    input[type="text"] {
      margin-bottom: 10px;
      padding: 8px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 3px;
      outline: none;
    }
  
    button {
      padding: 10px 20px;
      font-size: 16px;
      background-color: #dc3545;
      color: #fff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #f3051c;
      }
    }
  }
  