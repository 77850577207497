.container {
  padding: 0 ;
  @media screen and (max-width: 480px) {
    width: 100vw;
    padding: 0;
  }
}
.body {
  min-height: 400px;
  width: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.btnChat {
  position: fixed;
  border: none;
  border-radius: 50%;
  top: 90vh;
  right: 30px;
  z-index: 99;
  padding: 20px;
  background-color: #ff5100;
  fill: white;
  animation: pulse 2s infinite;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnBasket:hover {
  animation: none;
}

.iconWrapper {
  position: relative;
  width: 24px; /* Задайте потрібний розмір */
  height: 24px; /* Задайте потрібний розмір */
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.icon.hidden {
  opacity: 0;
  visibility: hidden;
}

.additionalButtons {
  position: fixed;
  top: 90vh;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

.additionalButtons.show {
  transform: translateY(-120%);
  opacity: 1;
  pointer-events: auto;
}


.additionalBtnTG {
  border: none;
  border-radius: 50%;
  padding: 15px;
  background-color: #1d98dc;
  fill: white;
  cursor: pointer;
}
.additionalBtnVB {
  border: none;
  border-radius: 50%;
  padding: 15px;
  background-color: #665cac;
  cursor: pointer;
}
