// Health.module.scss

.health-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  
}
