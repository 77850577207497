.accordion-container {
  margin: 20px auto;
  max-width: 800px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
  }

  .accordion-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;

    .accordion-header {
      // background-color: #495057; /* Задаем цвет заголовка */
      color: white; /* Цвет текста в заголовке */
      cursor: pointer;
      padding: 10px;
      border-bottom: 1px solid #ccc;

      &:hover {
        background-color: darken(#495057, 10%); /* Немного затемняем при наведении */
      }
    }

    .accordion-body {
      padding: 10px;
      background-color: #fff;
      color: #495057; /* Цвет текста в теле */
    }

    &:last-of-type .accordion-body {
      border-bottom: none;
    }

    &.active .accordion-header {
      background-color: darken(#495057, 10%); /* Немного затемняем для активного состояния */
    }
  }
}
